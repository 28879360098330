import axios from 'axios'
import { Message } from 'element-ui'
var host
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  host = 'https://questionaire-hz-questionnaire-ygkyqxbrrr.cn-hangzhou.fcapp.run/'
} else {
  host = 'https://questionaire-hz-questionnaire-ygkyqxbrrr.cn-hangzhou.fcapp.run/'
}
const service = axios.create({

  baseURL: host,
  timeout: 5000
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (typeof (config.data) == 'object')
        config.headers['Content-Type'] = 'application/json'
      config.data = JSON.stringify(config.data)
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
