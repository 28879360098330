import { getSing } from '@/api/index.js'
import wx from 'weixin-js-sdk'
export function getShareInfo() {
  //获取url链接（如果有#需要这么获取）
  // var url = encodeURIComponent(window.location.href.split('#')[0])
  //获取url链接（如果没有#需要这么获取）
  var url = encodeURIComponent(window.location.href);
  getSing(url).then((res) => {
    const { obj } = res
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: obj.appId, // 必填，公众号的唯一标识
      timestamp: parseInt(obj.timestamp), // 必填，生成签名的时间戳
      nonceStr: obj.nonceStr, // 必填，生成签名的随机串
      signature: obj.signature, // 必填，签名
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的 JS 接口列表
    })
    wx.ready(() => {
      var shareData = {
        title: '调查问卷',
        desc: `实习企业家调查问卷`,
        link: 'https://dcwj.alchiguru.com',
        // link: window.location.href,
        // imgUrl: 'https://pic.imgdb.cn/item/63f2e35df144a0100709fa43.png'
        imgUrl: 'https://agep-hz.oss-cn-hangzhou.aliyuncs.com/shan/logo.png'
      }
      //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      wx.updateAppMessageShareData(shareData)
      //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
      wx.updateTimelineShareData(shareData)
    })
    //错误了会走 这里
    wx.error(function (res) {
      console.log('微信分享错误信息', res)
    })
  })
}