import request from '@/api/request'
// 传送答案
export function send(data) {
  return request({
    url: `/api/submit`,
    method: 'post',
    data
  })
}
// 分享卡片
export function getSing(url) {
  return request({
    url: `/wx/getSignature?url=${url}`,
    method: 'get'
  })
}